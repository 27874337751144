import React from 'react'
import "./Support.css"
import FormComponent from './Components/FormComponent'

export default function Support() {

  const handleSubmit = () => {

  }

  return (
    <div className='py-[100px] px-4 md:px-[50px] lg:px-[90px] xl:px-[150px]'>
      <div className='bg-[#F8F9F9] support-body bg-cover ' style={{backgroundImage:'url(/assets/Images/bg.webp)'}}>
        <div className='flex gap-x-[100px] mb-[30px] flex-wrap  gap-y-[50px]'>
          <div className=' w-[100%] lg:w-[50%] flex-grow-0'>
            <h1 className=' text-[#18AD58] text-4xl font-semibold mb-[40px]'>WELCOME TO <span className='font-black'>INFINITEGROOVE LIMITED</span> BILLING SUPPORT</h1>
            <p className='text-gray-600 text-xs text-justify'> <span className='font-semibold '>InfiniteGroove Limited</span> provides secure and reliable website subscriptions, known for their trustworthiness and long-standing reputation. Any charges incurred will be listed as <span className='font-semibold '>igflix.net</span> on your billing statements. For any subscription-related queries or requests, you are welcome to contact us through our <span className='font-semibold '>Online Billing Support Form</span> or reach out to our billing department, which is available 24/7. The contact information for immediate assistance is listed below.</p>
          </div>
          <div className='flex-1'>
            <div className='w-full flex justify-end mb-6'>
              <img className=' w-[100px] block ' src='/assets/Images/img3.webp' />
            </div>
            <div className=' w-full h-4 bg-[#51C162] mb-6 rounded-full'></div>
            <div className='text-right text-gray-600'>
              <p className='text-lg mb-4 font-bold text-gray-500'>Billing support contact information:</p>
              <p className='text-xs text-gray-600'>International Phone: <b>+1 (325) 298-0325 </b><br/> or using</p>
              <p className='text-sm text-gray-500'><b>Online Billing Support Form</b></p>
            </div>
          </div>
        </div>
        <FormComponent></FormComponent>
      </div>

      
      <p className=' text-gray-700 text-sm mt-8 text-center' >© 2024 InfiniteGroove Limited</p>
      <p className=' text-gray-700 text-sm mt-1 text-center' >Pipinou, 6, 2320, Nicosia, Cyprus</p>
    </div>
  )
}
