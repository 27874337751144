import React, {useState, useEffect, useRef} from 'react'
import toast from 'react-hot-toast';
import { submitSupport } from '../HttpRequest'
import ReCAPTCHA from "react-google-recaptcha";


export default function FormComponent() {

    let loadingToast;
    const recaptcha = useRef();
    const [formValues, setFormValues]  = useState({email: '', description: ''});
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        console.log('Form Values: ', formValues);
    },[formValues])

    const handleInputChange = (e) => {
        setFormValues({
            ...formValues,
            [e?.target?.name] : e?.target.value
        })
    }
    
    const handleFormSubmit = (e) => {
        e.preventDefault();
        const captchaValue = recaptcha.current.getValue();

        if (!captchaValue) {
            // alert("Please verify the reCAPTCHA!");
            toast?.error("Please verify the reCAPTCHA!")
            // toast?.dismiss(loadingToast)
            setLoading(false);
            return;
        }      
        if(formValues?.email?.length > 5 && formValues?.description?.length > 1){
            loadingToast = toast?.loading("Loading");
            setLoading(true);

            submitSupport(formValues)?.then(response => {
                console.log(response)
                if(response){
                    console.log(response)
                    toast.success("Support Submitted successfully");
                    setFormValues({email: '', description: ''})
                    toast?.dismiss(loadingToast)
                    setLoading(false);

                }else{
                    toast?.error("Error Occured! Please try again later.")
                    toast?.dismiss(loadingToast)
                    setLoading(false);
                }
            })  

        }else{
            toast?.error('Please fill all fields')
        }
    }

    return (
        <form onSubmit={(e) => handleFormSubmit(e)} className='w-full'>
            <button className='block bg-[#51C162] text-lg px-2  md:text-2xl font-bold text-white w-full md:w-[70%] py-1 rounded-full mx-auto uppercase mb-[30px]'>
                Online Billing Support Form
            </button>

            <div className='md:w-[70%] mx-auto'>
                <p className='text-gray-600 text-center text-sm px-4 mb-[30px]'>We encourage you to provide detailed information and a clear description of your issue or question. This will help us to promptly and accurately address your concerns.</p>
                <div className='md:w-[60%] mx-auto'>
                <div className='mb-[15px]'>
                    <label className='text-xs font-semibold text-gray-600'>Email Address:</label>
                    <input name='email' type='email' required onChange={(e) => handleInputChange(e)} value={formValues?.email}  className='border border-gray-600 rounded block bg-transparent w-full  h-[42px] p-[5px]' />
                </div>   
                <div className='mb-[30px]'>
                    <label className='text-xs font-semibold text-gray-600'>Description of Problem or Question:</label>
                    <textarea name='description' required onChange={(e) => handleInputChange(e)} value={formValues?.description} className='block border border-gray-600 rounded bg-transparent w-full p-[5px]' rows={4}></textarea>
                </div> 
                <ReCAPTCHA ref={recaptcha} sitekey={"6Les6XEpAAAAAKomXKWyAW_gUpYOb3IfapxLq97B"} />
                    {
                        !loading ? <button type='submit' className='block bg-[#51C162] text-base font-bold text-white px-4 py-1 rounded-full mx-auto uppercase mt-8'>Submit Inquiry</button>   
                        : <button type='button' className='block bg-gray-400 text-base font-bold text-white px-4 py-1 rounded-full mx-auto uppercase mt-8'>Loading...</button>   
                    } 
                </div>
            </div>
        </form>
    )
}
