import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import Support from './Pages/Support/Support';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const IndexScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if(location?.pathname != "/support"){
      navigate("/support")
    }
  },[])
  
  return (
    <></>
  )
}


function App() {
  

  return (
    <BrowserRouter>
      <Routes>
        <Route index path='/' element={<IndexScreen />} />
        <Route index path='/support' element={<Support />} />
      </Routes>
      <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
              // Define default options
              className: '',
              duration: 5000,
              style: {
                background: '#363636',
                color: '#fff',
              },
              // Default options for specific types
              success: {
                duration: 3000,
                theme: {
                    primary: 'green',
                    secondary: 'black',
                },
              },
          }}
      />

    </BrowserRouter>
  );
}

export default App;
