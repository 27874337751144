import axios from "axios";


// export const BASE_URL = "https://api-onehubplay.thekreativestack.com";
export const BASE_URL = "https://onehubplay.com:8000";

// export const BASE_URL = "http://localhost:8000";



export const Http = axios.create({ baseURL: BASE_URL + "/api" });
Http.defaults.headers.common['Content-Type'] = 'application/json';