import axios from "axios";
import { Http } from "../../Utility/app";

export const submitSupport  = async (params) => {
    try {
        const response = await Http.post("/submit-support",params)
        if (response && response?.data?.status) {
            return response;
        }
        // console.log(response)
        return null; 
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}